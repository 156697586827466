import { fetchAPI } from "../utils/api"

export const getSelfAPI = async () => {
  return fetchAPI('marketplace/self')
}

export const loginUserMarketplace = async (payload: {
  email: string,
  password: string
}) => {
  return fetchAPI(`marketplace/auth/login`, {
    method: 'POST',
    payload
  })
}