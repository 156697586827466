import { authParamHeader } from "./common"

const purchaseOrderQueryParams = [
  {
    name: 'trade_order_id',
    in: 'query',
    description: 'ID of trade order to return',
    required: true,
    schema: {
      type: 'integer',
      format: 'int64'
    },
    example: 3086
  }, {
    name: 'reference_number',
    in: 'query',
    description: 'Reference number of trade order to return',
    required: true,
    schema: {
      type: 'string'
    },
    example: 'SS-0003086-000744'
  }
]

const purchaseOrderErrorResponses = {
  "400": {
    "description": "Bad Request"
  },
  '500': {
    description: 'Internal Server Error',
    content: {
      'application/json': {
        schema: {
          type: 'object',
          properties: {
            error_body: {
              type: ['string', 'null'],
              description: 'Error body',
              example: null
            },
            error_code: {
              type: ['string', 'null'],
              description: 'Error code',
              example: 'SERVER_ERROR'
            },
            error_message: {
              type: ['string', 'null'],
              description: 'Error description',
              example: 'list index out of range'
            }
          }
        },
      }
    }
  }
}

const tollGroupAPI = {
  "tags": [
    {
      "name": 'toll_group_webhooks',
      "x-displayName": "Toll Group Webhooks",
      "description": "Webhook for PO"
    },
    {
      name: 'toll_group_purchase_order',
      'x-displayName': 'Toll Group Purchase Order',
      description: 'API to get purchase order detail and receipt.'
    }
  ],
  'x-tagGroups': [
    {
      name: 'SourceSage', tags: [
        "oauth_api",
        "catalog_api",
        "webhooks"
      ]
    },
    {
      name: 'Toll Group', tags: ['toll_group_webhooks', 'toll_group_purchase_order']
    },
  ],
  paths: {
    "/toll-group/webhook": {
      "post": {
        "tags": [
          "toll_group_webhooks"
        ],
        "summary": "Webhooks Purchase Order",
        "description": "Webhook for Receive PO and PO Receipt",
        "produces": [
          "application/json"
        ],
        "parameters": [
          ...authParamHeader
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TollGroupPurchaseOrder"
              }
            }
          },
          "required": true
        },
        "responses": {
          "200": {
            "description": "On Success. Each Webhook event will have different response."
          },
          "400": {
            "description": "Bad Request"
          }
        }
      }
    },
    '/toll-group/unibis/purchase-order': {
      get: {
        tags: [
          'toll_group_purchase_order'
        ],
        summary: 'Unibis - Purchase Order',
        description: 'Get purchase order detail via unibis',
        produces: [
          'application/json'
        ],
        parameters: [
          ...authParamHeader,
          ...purchaseOrderQueryParams
        ],
        responses: {
          '200': {
            description: 'success'
          },
          ...purchaseOrderErrorResponses
        }
      }
    },
    '/toll-group/unibis/purchase-order-receipt': {
      get: {
        tags: [
          'toll_group_purchase_order'
        ],
        summary: 'Unibis - Purchase Order Receipt',
        description: 'Get purchase order receipt via unibis',
        produces: [
          'application/json'
        ],
        parameters: [
          ...authParamHeader,
          ...purchaseOrderQueryParams
        ],
        responses: {
          '200': {
            description: 'success'
          },
          ...purchaseOrderErrorResponses
        }
      }
    },
    '/toll-group/po-request': {
      get: {
        tags: [
          'toll_group_purchase_order'
        ],
        summary: 'Get PO Request',
        description: 'Get purchase order request',
        produces: [
          'application/json'
        ],
        parameters: [
          ...authParamHeader,
          ...purchaseOrderQueryParams
        ],
        responses: {
          '200': {
            description: 'success'
          },
          ...purchaseOrderErrorResponses
        }
      }
    }
  },
  components: {
    schemas: {
      TollGroupPurchaseOrder: {
        type: "object",
        required: ["event", 'payload'],
        properties: {
          event: {
            type: 'string',
            description: "Indicates the type of Event.",
            required: true,
            enum: [
              'ReceivePOPurchaseOrder',
              'ReceivePOReceipt',
              'PurchaseOrderReq'
            ]
          },
          payload: {
            type: 'object',
            description: 'Payload containing trade order ID and reference number',
            properties: {
              trade_order_id: {
                type: 'number',
                required: true,
                example: 3086
              },
              reference_number: {
                type: 'string',
                required: true,
                example: "SS-0003086-000744"
              }
            }
          }
        }
      }
    }
  }
}

export default tollGroupAPI