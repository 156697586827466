import { useState } from "react";
import styled from "styled-components";
import { loginUserMarketplace } from "../api/user";
import FullScreenLoading from "./FullScreenLoading";

const NotAllowedNeedToLogin = (props: {
  marketplaceId: number;
  successLogin: () => void;
}) => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const loginUser = async () => {
    if (!formData.username || !formData.password) {
      return;
    }

    try {
      setLoading(true);
      const resp = await loginUserMarketplace({
        email: formData.username,
        password: formData.password,
      });

      if (resp.marketplace_id === props.marketplaceId) {
        localStorage.setItem("docs_csrf_access_token", resp.csrf_token);
        localStorage.setItem("docs_ss_token", resp.token);

        props.successLogin();
      } else {
        window.alert("User not authorized!");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      window.alert("Invalid Credentials");
    }
  };

  if (loading) {
    return <FullScreenLoading />;
  }

  return (
    <Wrapper>
      <div className="content">
        <h3>Unauthorized</h3>
        <p className="subtitle">
          You are not allowed to access this documentation, please login to get
          your access
        </p>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            loginUser();
          }}
          method="post"
        >
          <input
            type="email"
            placeholder="Email"
            value={formData.username}
            onChange={(e) => {
              e.persist();
              setFormData((fd) => ({ ...fd, username: e.target?.value }));
            }}
          />
          <input
            type="password"
            placeholder="Password"
            value={formData.password}
            onChange={(e) => {
              e.persist();
              setFormData((fd) => ({ ...fd, password: e.target?.value }));
            }}
          />
          <button type="submit">Log In</button>
        </form>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-family: Montserrat, sans-serif;

  .content {
    width: 800px;
    max-width: calc(100vw - 30px);
    padding: 15px;
    margin: 0 auto;
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin: 15px auto 0;

    input {
      height: 30px;
      border: none;
      outline: none;
      padding: 0 5px;
      margin-bottom: 15px;
      border-radius: 5px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom: solid 1px #aeaeae;
    }

    button {
      width: fit-content;
      padding: 10px 25px;
      margin: 15px auto 0;
      background-color: #43168a;
      color: #fff;
      outline: none;
      border: none;
      border-radius: 5px;

      &:hover {
        cursor: pointer;
        box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.5);
      }
    }
  }
`;

export default NotAllowedNeedToLogin;
