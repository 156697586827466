import React, { useEffect, useState } from "react";
import { getSelfAPI } from "../api/user";
import FullScreenLoading from "./FullScreenLoading";
import NotAllowedNeedToLogin from "./NotAllowedNeedToLogin";

const AuthorizedDocs = (props: { marketplaceId: number; children: any }) => {
  const [authorized, setAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);

  const getSelf = async () => {
    try {
      setLoading(true);
      const self = await getSelfAPI();
      if (self.marketplace_id === props.marketplaceId) {
        setAuthorized(true);
      } else {
        localStorage.clear();
        window.alert("Unauthorized");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setAuthorized(false);
    }
  };

  useEffect(() => {
    getSelf();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <FullScreenLoading />;
  }

  if (!authorized) {
    return (
      <NotAllowedNeedToLogin
        marketplaceId={props.marketplaceId}
        successLogin={() => {
          setAuthorized(true);
          setLoading(false);
        }}
      />
    );
  }

  return <div className="App">{props.children}</div>;
};

export default AuthorizedDocs;
