const defaultHeaders = {
  'Content-Type': 'application/json',
};
class HTTPError extends Error {
  code?: string;
  errorBody?: string;
  constructor(errorMessage: string, errorCode: string, errorBody?: string) {
    super(errorMessage);
    this.code = errorCode;
    this.errorBody = errorBody;
  }
}

export const fetchAPI: (endpoint: string, options?: {
  payload?: any;
  version?: string;
  method?: string;
}) => Promise<any> = async (endpoint, options) => {
  const version = options?.version || '1.0'
  const method = options?.method || 'GET'
  const body = !!options?.payload ? JSON.stringify(options?.payload) : undefined;
  let headers: any = defaultHeaders

  const CRSFAccessToken = localStorage.getItem('docs_csrf_access_token');
  const accessToken = localStorage.getItem('docs_ss_token');

  if (CRSFAccessToken) {
    headers['X-CSRF-TOKEN'] = CRSFAccessToken;
  }
  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }
  
  const rawResp = await window.fetch(`${process.env.REACT_APP_API_URL}/api/v${version}/${endpoint}`, {
    body,
    method,
    headers: new Headers(headers),
    credentials: 'include'
  })

  if (rawResp.ok) {
    return rawResp.json()
  } else {
    const json = await rawResp.json();

    throw new HTTPError(json.error_message, json.error_code, json.error_body);
  }
}