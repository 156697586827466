export const authParamHeader = [
  {
    "name": "Authorization",
    "in": "header",
    "description": "Your Bearer + client token.",
    "required": true,
    "schema": {
      "type": "string"
    },
    "example": "Bearer {{client_token}}"
  },
  {
    "name": "SS-Token",
    "in": "header",
    "description": "Your unique SS-Token.",
    "required": true,
    "schema": {
      "type": "string"
    }
  }
]