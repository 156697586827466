// import "./App.css";
import BaseAPI from "./api-paths/sourcesage-api.json";
import { RedocStandalone } from "redoc";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import tollGroupAPI from "./api-paths/tollgroup-api";
import AuthorizedDocs from "./components/AuthorizedDocs";
import punchoutAPI from "./api-paths/punchout-api";

const SourceSageAPI = {
  ...BaseAPI,
  tags: [...BaseAPI.tags, ...punchoutAPI.tags],
  paths: { ...BaseAPI.paths, ...punchoutAPI.paths },
};

const BasicDocs = () => {
  return (
    <div className="App">
      <RedocStandalone
        spec={SourceSageAPI}
        options={{
          nativeScrollbars: true,
          theme: { colors: { primary: { main: "#43168a" } } },
        }}
      />
    </div>
  );
};

const TollGroupDocs = () => {
  const docsSpec = {
    ...SourceSageAPI,
    ...tollGroupAPI,
    tags: [...SourceSageAPI.tags, ...tollGroupAPI.tags],
    paths: {
      ...SourceSageAPI.paths,
      ...tollGroupAPI.paths,
    },
    components: {
      schemas: {
        ...SourceSageAPI.components.schemas,
        ...tollGroupAPI.components.schemas,
      },
    },
  };

  // toll group staging marketplace id
  const marketplaceId = 40;

  // mcbride mcguire
  // const marketplaceId = 2;

  return (
    <AuthorizedDocs marketplaceId={marketplaceId}>
      <RedocStandalone
        spec={docsSpec}
        options={{
          nativeScrollbars: true,
          theme: { colors: { primary: { main: "#43168a" } } },
        }}
      />
    </AuthorizedDocs>
  );
};

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<BasicDocs />} />
          <Route path="toll-group" element={<TollGroupDocs />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
