import React from "react";
import styled, { keyframes } from "styled-components";

const thickening = keyframes`
  0% {
    opacity: 0.3;
  }

  25% {
    opacity: 1;
  }
`;

const FullScreenLoading = () => {
  return (
    <Wrapper>
      <div className="loading-container">
        <div className="loading-box" />
        <div className="loading-box" />
        <div className="loading-box" />
        <div className="loading-box" />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .loading-container {
    display: flex;
    width: 105px;
    height: 105px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: space-between;

    .loading-box {
      width: 50px;
      height: 50px;
      background-color: #999;
      border-radius: 10px;
      margin: 0 auto;
      opacity: 0.3;
      animation: ${thickening} 1.6s ease-in infinite;

      &:nth-child(1) {
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 30px;
      }

      &:nth-child(2) {
        border-bottom-left-radius: 0px;
        border-top-left-radius: 30px;
        animation-delay: 0.4s;
      }

      &:nth-child(3) {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 30px;
        animation-delay: 1.2s;
      }

      &:nth-child(4) {
        border-top-left-radius: 0px;
        border-top-right-radius: 30px;
        animation-delay: 0.8s;
      }
    }
  }
`;

export default FullScreenLoading;
